<template>
  <b-modal
      v-model="showUser"
      centered
      :title="title"
      size="m"
      title-class="font-18"
      hide-footer
  >
    <div class="pb-3 border-dash check-table-top">
      <div class="flexList">
        <YzSearch
            :data="search"
            v-model="pageData"
            @seachList="searchClick"
        ></YzSearch>
      </div>
    </div>
    <div class="table-responsive border mt-3">
      <table class="table light-table table-hover">
        <thead class="thead-light">
        <tr>
          <th style="width: 10%">序号</th>
          <th style="width: 5%">姓名</th>
          <th style="width: 10%">账号</th>
          <th>部门</th>
          <th style="width: 11%">选择</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in items" :key="i" @dblclick="choosePerson(item)">
          <td scope="row">{{ i + 1 }}</td>
          <td>
            <a href="javascript:;" class="blue-font"  @click="$yzAdminUserInfo().show(item.yhzh)">{{ item.xm }}</a>
          </td>
          <td>{{ item.yhzh }}</td>
          <td>{{ item.bmmc }}</td>
          <th>
            <el-button size="mini" type="primary" @click="choosePerson(item)">选择</el-button>
          </th>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="float-right d-flex mb-2">
      <el-pagination
          @size-change="handleSizeChange"
          background
          @current-change="handleCurrentChange"
          :current-page.sync="pageData.pageNum"
          :page-size="pageData.pageSize"
          layout="total, sizes,jumper, prev, pager, next"
          :total="pageData.total"
      >
      </el-pagination>
    </div>
    <div class="mt-3 text-center" style="clear: both;">
      <button
          type="button"
          class="btn btn-secondary h30 w-md"
          @click="showUser = false"
      >
        关闭窗口
      </button>
    </div>
  </b-modal>
</template>

<script>
import {getUserAdmin} from "@/api/admin/user/userAdmin";
import YzSearch from "@/components/form/yzSearch";

export default {
  name: "chooseAdmin",
  props:{
    title: {
      default: "管理人员选择",
    }
  },
  components:{
    YzSearch,
  },
  data(){
    return {
      showUser: false,
      pageData: {},
      items: [],
      search: []
    }
  },
  methods: {
    choosePerson(item){
      this.hide()
      this.$emit("callback", item)
    },
    show(){
      this.getUserList()
      this.showUser = true
    },
    hide(){
      this.showUser = false
    },
    searchClick() { //搜索时先将分页的页码归1
      this.pageData.pageNum = 1; //当前页				
      this.getUserList() //渲染数据的函数
    },
    getUserList() {
      getUserAdmin("admin", this.pageData).then((res) => {
        if (res.status) {
          this.items = res.data;
          this.pageData.total = res.total;
        }
      });
    },
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.getUserList();
    },
    handleCurrentChange(val) {
      this.pageData.pageNum = val;
      this.getUserList();
    },
  },
  mounted() {
    this.search = [
      {
        title: "姓名/账号/身份证件号",
        bound: "keyword",
        type: "input",
      },
    ];
  }
}
</script>

<style scoped>

</style>